import { Badge } from "reactstrap"
import axios from "axios"
import { useState } from "react"
import { components } from "react-select"
import { FileText } from "react-feather"
export const finYear = "23-24"

export const productObj = {
   product_serial_no: 0,
   product_name: "",
   HSN: "",
   unit: "",
   quantity: 0,
   base_price: 0,
   total_price: 0
}
export const numberToWords = (number) => {
   const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"]
   const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"]
   const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"]

   function convertToWords(num) {
      if (num < 10) {
         return units[num]
      } else if (num < 20) {
         return teens[num - 10]
      } else {
         const digit = num % 10
         const tenDigit = Math.floor(num / 10)
         return tens[tenDigit] + (digit !== 0 ? " " + units[digit] : "")
      }
   }

   function convertChunk(chunk, chunkName) {
      const hundredDigit = Math.floor(chunk / 100)
      const remainder = chunk % 100
      let result = ""

      if (hundredDigit !== 0) {
         result += units[hundredDigit] + " hundred "
      }

      if (remainder !== 0) {
         if (result !== "") {
            result += " and "
         }
         result += convertToWords(remainder)
      }

      return result !== "" ? result + " " + chunkName : ""
   }

   if (number === 0) {
      return "zero"
   }

   let result = ""

   result += convertChunk(Math.floor(number / 1000000000), " billion ")
   result += convertChunk(Math.floor((number % 1000000000) / 1000000), " million ")
   result += convertChunk(Math.floor((number % 1000000) / 1000), " thousand ")
   result += convertChunk(number % 1000, "")

   return result.trim()
}

export const AddPlusIcon = () => {
   return (
      <svg width="37" height="25" viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="46.0909" height="39" rx="4.96364" fill="#F3F3F3" />
         <path d="M21.3068 26.6442V21.4707H16.2013V17.5906H21.3068V12.417H25.3231V17.5906H30.4285V21.4707H25.3231V26.6442H21.3068Z" fill="#0057FF" />
      </svg>
   )
}

export const DeleteIcon = () => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z"
            fill="#FF3030"
         />
         <path
            d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z"
            fill="#FF3030"
         />
      </svg>
   )
}
export const gettoken = () => {
   return localStorage.getItem("token")
}

export function getCookieByName(cookiename) {
   // Get name followed by anything except a semicolon
   const cookiestring = RegExp(`${cookiename}=[^;]+`).exec(document.cookie)
   // Return everything after the equal sign, or an empty string if the cookie name not found
   return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "")
}

export const instance = () => {
   return axios.create({
      baseURL: `http://localhost:3100`,
      headers: { Authorization: `Bearer ${gettoken()}` },
      mode: "no cors"
   })
}

export const instance2 = axios.create({
   baseURL: "http://localhost:3100", // Set your API base URL
   timeout: 5000 // Set a timeout for requests (adjust as needed)
})

export const projectStatus = {
   pending: (
      <Badge className="text-white" color="primary" pill>
         DRAFT
      </Badge>
   ),
   accepted: (
      <Badge className="text-white" color="success" pill>
         PUBLISHED
      </Badge>
   )
}
export const taxOptions = [
   {
      value: 0,
      label: "0 %"
   },
   {
      value: 5,
      label: "5 %"
   },
   {
      value: 6,
      label: "6 %"
   },
   {
      value: 9,
      label: "9 %"
   },
   {
      value: 14,
      label: "14 %"
   }
]

export const dummyItemOptions = [
   {
      value: 1,
      label: "pant",
      color: [
         { color: "green", quantity: 0 },
         { color: "kesari", quantity: 0 }
      ],
      price: 25,
      totalPrice: 0,
      totalQuantity: 0
   },
   {
      value: 2,
      label: "chaddo",
      color: [
         { color: "blue", quantity: 0 },
         { color: "red", quantity: 0 },
         { color: "yellow", quantity: 0 }
      ],
      price: 26,
      totalPrice: 0,
      totalQuantity: 0
   },
   {
      value: 3,
      label: "shal",
      color: [
         { color: "gray", quantity: 0 },
         { color: "neon", quantity: 0 }
      ],
      price: 27,
      totalPrice: 0,
      totalQuantity: 0
   },
   {
      value: 4,
      label: "short",
      color: [
         { color: "light gray", quantity: 0 },
         { color: "bilu", quantity: 0 }
      ],
      price: 27,
      totalPrice: 0,
      totalQuantity: 0
   }
]
export const renderFilePreviewForFile = (file) => {
   if (file.type.startsWith("image")) {
      return <img className="rounded" alt={file.name} src={URL.createObjectURL(file)} height="40" width="70" />
   } else {
      return <FileText size="28" />
   }
}

export const renderFilePreviewForUrl = (imageUrl, height, width) => {
   // console.log(URL.createObjectURL(file))
   // if (file.type.startsWith("image")) {
   return <img className=" rounded" alt={"defauit"} src={imageUrl} height={`${height || 45}px`} width={`${width || 45}px`} />
   // } else {
   // return <FileText size="28" />
   // }
}
export const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
   const [isActive, setIsActive] = useState(false)
   const onMouseDown = () => setIsActive(true)
   const onMouseUp = () => setIsActive(false)
   const onMouseLeave = () => setIsActive(false)

   // styles
   let bg = "transparent"
   if (isFocused) bg = "#eee"
   if (isActive) bg = "#B2D4FF"

   const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex "
   }

   // prop assignment
   const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
   }

   return (
      <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles} innerProps={props}>
         <input type="checkbox" readOnly checked={isSelected} />
         &nbsp; &nbsp; {children}
      </components.Option>
   )
}
export const customStyles = {
   rows: {
      style: {
         whiteSpace: "pre-line"
      }
   },
   headcells: {
      style: {
         // justifyContent: "center",
         fontSize: "12px",
         lineHeight: "15px",
         letterSpacing: "1px",
         color: "#515C68",
         fontFamily: "Space Grotesk",
         fontStyle: "normal",
         fontWeight: "500",
         whiteSpace: "pre-line",
         backgroundColor: "#f9fafb",
         textTransform: "uppercase"
      }
   },
   cells: {
      style: {
         // justifyContent: "center",
         fontSize: "14px",
         lineHeight: "18px",
         letterSpacing: "1px",
         fontFamily: "Space Grotesk",
         fontStyle: "normal",
         fontWeight: "400",
         paddingLeft: "10px",
         // lineHeight: '21px',
         color: "#1B222B"
      }
   }
}

export const dummyDataForDataTable = [
   {
      id: 1,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "4.2",
      tags: 1,
      class_status: true,
      price: 5000,
      level: 1,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 2,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "adult",
      rating: "4.2",
      tags: 2,
      class_status: false,
      price: 5000,
      level: 3,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 3,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "4.2",
      tags: 1,
      class_status: true,
      price: 5000,
      level: 1,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 4,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "adult",
      rating: "4.2",
      tags: 0,
      class_status: true,
      price: 5000,
      level: 3,
      start_date: "09/23/2016",
      status: 1
   },
   {
      id: 5,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "4.2",
      tags: 1,
      class_status: true,
      price: 5000,
      level: 1,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 6,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "4.2",
      tags: 1,
      class_status: true,
      price: 5000,
      level: 2,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 7,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "adult",
      rating: "4.2",
      tags: 1,
      class_status: false,
      price: 5000,
      level: 1,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 8,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "4.2",
      tags: 1,
      class_status: true,
      price: 5000,
      level: 1,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 9,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "4.2",
      tags: 1,
      class_status: false,
      price: 5000,
      level: 3,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 10,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "adult",
      rating: "4.5",
      tags: 1,
      class_status: false,
      price: 5000,
      level: 1,
      start_date: "09/23/2016",
      status: 2
   },
   {
      id: 11,
      classes_id: "132",
      subject: "music",
      class_title: "basic guitar advance",
      age: "kid",
      rating: "3.2",
      tags: 1,
      class_status: false,
      price: 5000,
      level: 2,
      start_date: "09/23/2016",
      status: 2
   }
]
