// ** React Imports
import { Fragment, useState, useContext } from "react"
// ** Third Party Components
import ReactPaginate from "react-paginate"
import DataTable from "react-data-table-component"
import { ChevronDown, MoreVertical, Edit3, PlusCircle, Info } from "react-feather"

// ** Reactstrap Imports
import { Card, Input, Button, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap"
import { useNavigate } from "react-router-dom"

// import SearchInput from "../../../../UI-componant/SearchInput"
import { customStyles } from "../../utils"
import { useEffect } from "react"
// import { categoriesColumnList, genericServicesColumnList, genericServicesDemoColumnList } from "../genericServicesOptions"
import { ordersColumnList } from "../tableColumn"
import { getAllOrder } from "../../myApiCenter"
import { OrderContext } from "../../context/can"
import PdfGenerator from "../../componant/PDFGenerator"
const OrderList = () => {
   const navigate = useNavigate()
   const [, idListSetter] = useContext(OrderContext)

   const [currentPage, setCurrentPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(10)
   const [data, setdata] = useState([])
   const [generatePdf, setGeneratePdf] = useState(false)
   const [pdfData, setpdfData] = useState({})
   const getData = async () => {
      const j = await getAllOrder()
      console.log(j)
      const idList = j?.data?.map((e, index) => {
         return { serial: index + 1, id: e._id }
      })
      localStorage.setItem("orderIdList", JSON.stringify(idList))
      idListSetter(idList)

      setdata(j.data)
   }
   const columns = [
      ...ordersColumnList,
      {
         name: "Action",
         allowOverflow: true,
         cell: (row) => {
            return (
               <div className="d-flex">
                  <UncontrolledDropdown>
                     <DropdownToggle style={{ cursor: "pointer" }} className="pe-1" tag="span">
                        <MoreVertical size={15} />
                     </DropdownToggle>
                     <DropdownMenu end>
                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              navigate(`/orders/edit/${row._id}`)
                           }}
                        >
                           <Edit3 size={24} />
                           <span className="align-middle ms-50">Edit Info</span>
                        </DropdownItem>
                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              navigate(`/orders/view/${row._id}`)
                           }}
                        >
                           <Info size={24} />
                           <span className="align-middle ms-50">View Info</span>
                        </DropdownItem>
                        <DropdownItem
                           tag="div"
                           className="w-100"
                           onClick={() => {
                              setpdfData(() => {
                                 return { ...row }
                              })
                              setGeneratePdf(true)

                              setTimeout(() => {
                                 console.log("run")
                                 setGeneratePdf(false)
                              }, 100)
                           }}
                        >
                           <Info size={24} />
                           <span className="align-middle ms-50">Print Bill</span>
                        </DropdownItem>
                     </DropdownMenu>
                  </UncontrolledDropdown>
               </div>
            )
         }
      }
   ]

   // ** Function to handle Pagination
   const handlePagination = (page) => {
      setCurrentPage(page.selected)
   }
   const handlePerPage = (e) => {
      setCurrentPage(0)
      setRowsPerPage(parseInt(e.target.value))
   }
   useEffect(() => {
      getData()

      return () => {}
   }, [])
   const ExpandedComponent = ({ data }) => (
      <table className="view-order-detail w-100 ">
         <thead>
            <tr>
               <th>No</th>
               <th>Item/Service Name</th>
               <th>HSN Code </th>

               <th>Price </th>
               <th>Quantity</th>
               <th>Unit</th>
               <th>total</th>
            </tr>
         </thead>
         <tbody>
            {data.products?.map((e, index) => {
               return (
                  <tr key={index}>
                     <td>{e.product_serial_no}</td>
                     <td>{e.product_name}</td>
                     <td>{e.HSN} </td>

                     <td>{e.base_price} ₹ </td>
                     <td>{e.quantity}</td>
                     <td>{e.unit} </td>

                     <td>{e.total_price} ₹</td>
                  </tr>
               )
            })}
         </tbody>
      </table>
   )
   // ** Custom Pagination
   const CustomPagination = (props) => {
      return (
         <div className="mobile-users-table-footer">
            <div>
               {currentPage * rowsPerPage + 1} TO {(currentPage + 1) * rowsPerPage < data.length ? (currentPage + 1) * rowsPerPage : data.length} of{" "}
               {data.length} items
            </div>
            <div className="mobile-users-table-footer-last">
               <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  forcePage={data.length > rowsPerPage ? currentPage : 0}
                  onPageChange={(page) => handlePagination(page)}
                  pageCount={Math.ceil(data.length / rowsPerPage) || 1}
                  breakLabel="..."
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  activeClassName="active"
                  pageClassName="page-item"
                  breakClassName="page-item"
                  nextLinkClassName="page-link"
                  pageLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextClassName="page-item next-item"
                  previousClassName="page-item prev-item"
                  containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
               />

               <div>
                  <Input
                     type="select"
                     id="rows-per-page"
                     value={rowsPerPage}
                     onChange={(e) => {
                        props.onChangeRowsPerPage(e.target.value)
                        handlePerPage(e)
                     }}
                     className="page-selector"
                  >
                     <option value="5">5</option>
                     <option value="7">7</option>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                  </Input>
               </div>
            </div>
         </div>
      )
   }

   console.log(data)

   return (
      <Fragment>
         <Card>
            {generatePdf && <PdfGenerator data={pdfData} />}

            <div className="react-dataTable react-dataTable-selectable-rows">
               <DataTable
                  customStyles={customStyles}
                  pagination
                  sortactive={false}
                  columns={columns}
                  paginationPerPage={rowsPerPage}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  className="react-dataTable"
                  sortIcon={<ChevronDown size={10} />}
                  paginationComponent={CustomPagination}
                  paginationDefaultPage={currentPage + 1}
                  data={data}
                  subHeader={true}
                  subHeaderComponent={
                     <div className="d-flex p-1 w-100 justify-content-between align-items-center">
                        <h1 className="datatable-title-head">Order List</h1>
                        <div className="d-flex gap-1 align-items-center">
                           {/* <div style={{ width: "200px" }}>
                              <SearchInput onChange={() => {}} />
                           </div> */}

                           <Button className="my-btn" onClick={() => navigate("/orders/add")}>
                              <PlusCircle />
                              &nbsp; Add Order
                           </Button>
                        </div>
                     </div>
                  }
               />
            </div>
         </Card>
      </Fragment>
   )
}
export default OrderList
