import { NavLink } from "react-router-dom"

const MainNav = () => {
   return (
      <div className="d-flex  justify-content-center background-light align-items-center  navbar1" style={{ width: "98vw" }}>
         <NavLink
            // className={({ isActive }) => (isActive ? "active" : "inactive")}
            className={({ isActive }) => "nav-item" + (isActive ? " active" : "")}
            // className="active"
            to={"/"}
         >
            <span style={{ scale: "1", listStyle: "none", marginTop: "50px" }}>
               {/* <img height="50px" width="50px" src={"a"} alt="logo" /> */}
               Home
            </span>
         </NavLink>
         <NavLink className={({ isActive }) => "nav-item" + (isActive ? " active" : "")} to={"/orders"}>
            <span style={{ scale: "1", listStyle: "none", marginTop: "50px" }}>
               {/* <img height="50px" width="50px" src={"a"} alt="logo" /> */}
               Order List
            </span>
         </NavLink>

         {/* <NavLink className={({ isActive }) => "nav-item" + (isActive ? " active" : "")} to={"/others"}>
            <span style={{ scale: "1", listStyle: "none", marginTop: "50px" }}>
               <img height="50px" width="50px" src={"a"} alt="logo" />
               Others
            </span>
         </NavLink> */}
      </div>
   )
}

export default MainNav
