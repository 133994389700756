export const ordersColumnList = [
   {
      name: "BILL NO",
      sortable: true,
      minWidth: "100px",
      selector: (row) => row.orderNo
   },
   {
      name: "BILL DATE",
      sortable: true,
      minWidth: "150px",
      selector: (row) => row.orderDate?.split("T")[0].split("-").reverse().join("/")
   },
   {
      name: "CUSTOMER NAME",
      sortable: true,
      minWidth: "100px",
      selector: (row) => row.customer?.name
   },

   {
      name: "TOTAL GST",
      sortable: true,
      minWidth: "150px",
      selector: (row) => row.totalGST.total + " ₹"
   },
   {
      name: "GRAND TOTAL",
      sortable: true,
      minWidth: "150px",
      selector: (row) => row.grandTotal + " ₹"
   }
]
