import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "flatpickr/dist/flatpickr.min.css"
// ** React Perfect Scrollbar
// import "react-perfect-scrollbar/dist/css/styles.css"

// import "datatables-css-file.css"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import RootLayout from "./RootLayout"
import Home from "./componant/Home"
import { Toaster } from "react-hot-toast"
import OrderList from "./pages/Orders/OrderList"
import AddOrder from "./pages/Orders/AddOrder"
import ViewOrder from "./pages/Orders/ViewOrder"
import OrdersProvider from "./context/OrdersProvider"

function App() {
   const router = createBrowserRouter([
      {
         path: "/",
         element: <RootLayout />,
         children: [
            { path: "/", element: <Home /> },
            { path: "/home", element: <Home /> },
            { path: "/orders", element: <OrderList /> },
            { path: "/orders/add", element: <AddOrder /> },
            { path: "/orders/edit/:id", element: <AddOrder /> },
            { path: "/orders/view/:id", element: <ViewOrder /> }
         ]
      }
   ])
   return (
      <div className="App">
         <OrdersProvider>
            <RouterProvider router={router}></RouterProvider>
            <Toaster />
         </OrdersProvider>
         {/* <RootLayout></RootLayout> */}
      </div>
   )
}

export default App
