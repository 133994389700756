import React, { useEffect } from "react"
import { pdfjs } from "react-pdf"
import { useReactToPrint } from "react-to-print"
// import MyComponent from "./MyComponent"
import { PrintableComponent } from "./PrintableComponent"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const PdfGenerator = (props) => {
   const componentRef = React.useRef()

   const handlePrint = useReactToPrint({
      content: () => componentRef.current
   })

   useEffect(() => {
      handlePrint()
      return () => {}
   }, [])

   return (
      <div>
         {/* <button onClick={handlePrint}>Print Component</button> */}
         <PrintableComponent data={props.data} ref={componentRef} />
      </div>
   )
   //    const handlePrint = () => {
   //       window.print()
   //    }
   //    return (
   //       <div>
   //          <Document>
   //             <Page>
   //                <div>Your content goes here</div>
   //             </Page>
   //          </Document>
   //          <button onClick={handlePrint}>Print PDF</button>
   //       </div>
   //    )
}

export default PdfGenerator
