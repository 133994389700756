import { instance } from "./utils"
import { toast } from "react-hot-toast"
/*********************** ** */

/*********************** ** */

export const postOrder = async (data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.post("api/orders", data)
      toast.success("order added successfully")
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message} or order already exist `)
   }
}
export const putOrderById = async (id, data) => {
   const newInstance = instance()
   try {
      const j = await newInstance.patch(`api/orders/${id}`, data)
      toast.success("order updated successfully")
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message} or order already exist `)
   }
}
export const getOrderById = async (id) => {
   const newInstance = instance()
   try {
      const j = await newInstance.get(`api/orders/${id}`)
      // toast.success("type added successfully")
      return j
   } catch (error) {
      console.log(error)
      toast.error(`${error.response.data.message} or customer already exist `)
   }
}

export const getAllOrder = async () => {
   const newInstance = instance()
   try {
      const j = await newInstance.get("api/orders")
      return j
   } catch (error) {
      console.log(error)
   }
}
/*********************** ** */
