import { useEffect } from "react"
import { Input } from "reactstrap"
import { DeleteIcon } from "../../utils"

const ItemWiseOrder = (props) => {
   useEffect(() => {
      return () => {
         // setitem(oldItem)
      }
   }, [props.data])

   // console.log(props.data)
   return (
      <tr>
         <td>{props.index + 1}</td>
         <td>
            <Input
               value={props.data.product_name}
               required
               onChange={(e) => {
                  props.setData((prev) => {
                     const oldProducts = [...prev.products]
                     oldProducts[props.index].product_name = e.target.value
                     return { ...prev, products: [...oldProducts] }
                  })
               }}
               type="text"
               placeholder="Enter product Name"
            />
         </td>
         <td>
            <Input
               value={props.data.HSN}
               // required
               onChange={(e) => {
                  props.setData((prev) => {
                     const oldProducts = [...prev.products]
                     oldProducts[props.index].HSN = e.target.value
                     return { ...prev, products: [...oldProducts] }
                  })
               }}
               type="text"
               placeholder="Enter HSN code"
            />
         </td>
         <td>
            <Input
               value={props.data.base_price}
               required
               onChange={(e) => {
                  props.setData((prev) => {
                     const oldProducts = [...prev.products]
                     oldProducts[props.index].base_price = +e.target.value
                     oldProducts[props.index].total_price = +e.target.value * oldProducts[props.index].quantity
                     const totalWithoutGST = Math.round(oldProducts.map((m) => m.total_price).reduce((k, a) => k + a, 0))
                     const oldCGST = { ...prev.CGST }
                     oldCGST.total = Math.round((totalWithoutGST * oldCGST.per) / 100)
                     const oldSGST = { ...prev.SGST }
                     oldSGST.total = Math.round((totalWithoutGST * oldSGST.per) / 100)
                     return {
                        ...prev,
                        products: [...oldProducts],
                        totalWithoutGST,
                        CGST: oldCGST,
                        SGST: oldSGST,
                        totalGST: { per: oldCGST.per + oldSGST.per, total: oldCGST.total + oldSGST.total },
                        grandTotal: totalWithoutGST + oldCGST.total + oldSGST.total
                     }
                  })
               }}
               type="number"
               placeholder="Enter price"
            />
         </td>
         <td>
            <Input
               value={props.data.quantity}
               required
               onChange={(e) => {
                  props.setData((prev) => {
                     const oldProducts = [...prev.products]
                     oldProducts[props.index].quantity = +e.target.value
                     oldProducts[props.index].total_price = +e.target.value * oldProducts[props.index].base_price
                     const totalWithoutGST = Math.round(oldProducts.map((m) => m.total_price).reduce((k, a) => k + a, 0))
                     const oldCGST = { ...prev.CGST }
                     oldCGST.total = Math.round((totalWithoutGST * oldCGST.per) / 100)
                     const oldSGST = { ...prev.SGST }
                     oldSGST.total = Math.round((totalWithoutGST * oldSGST.per) / 100)
                     return {
                        ...prev,
                        products: [...oldProducts],
                        totalWithoutGST,
                        CGST: oldCGST,
                        SGST: oldSGST,
                        totalGST: { per: oldCGST.per + oldSGST.per, total: oldCGST.total + oldSGST.total },
                        grandTotal: totalWithoutGST + oldCGST.total + oldSGST.total
                     }
                  })
               }}
               type="number"
               placeholder="Enter Quantity"
            />
         </td>
         <td>
            <Input
               value={props.data.unit}
               // required
               onChange={(e) => {
                  props.setData((prev) => {
                     const oldProducts = [...prev.products]
                     oldProducts[props.index].unit = e.target.value
                     return { ...prev, products: [...oldProducts] }
                  })
               }}
               type="text"
               placeholder="Enter UNIT of material"
            />
         </td>
         <td>{props.data.total_price}</td>
         <td
            style={{ cursor: "pointer" }}
            onClick={() => {
               console.log(props.index)
               props.setData((prev) => {
                  const oldProducts = [...prev.products]
                  if (oldProducts.length > 1) {
                     oldProducts.splice(props.index, 1)
                     console.log(oldProducts)
                     const totalWithoutGST = Math.round(oldProducts.map((m) => m.total_price).reduce((k, a) => k + a, 0))
                     const oldCGST = { ...prev.CGST }
                     oldCGST.total = Math.round((totalWithoutGST * oldCGST.per) / 100)
                     const oldSGST = { ...prev.CGST }
                     oldSGST.total = Math.round((totalWithoutGST * oldSGST.per) / 100)
                     return {
                        ...prev,
                        products: [...oldProducts],
                        totalWithoutGST,
                        CGST: oldCGST,
                        SGST: oldSGST,
                        totalGST: { per: oldCGST.per + oldSGST.per, total: oldCGST.total + oldSGST.total },
                        grandTotal: totalWithoutGST + oldCGST.total + oldSGST.total
                     }
                  } else {
                     return { ...prev }
                  }
               })
            }}
         >
            <DeleteIcon />
         </td>
      </tr>
   )
}
export default ItemWiseOrder
