import { Link } from "react-router-dom"
// import mainFront from "../assets/main-front.PNG"

const Home = () => {
   return (
      <>
         <div className="d-flex flex-wrap align-items-center h-75 w-100 justify-content-center">
            <div className="d-flex mt-5 flex-wrap text-primary flex-column align-items-center m-4">
               <h1>Shree Ram Enterprise</h1>
               <div className="d-flex gap-3 mt-5">
                  {/* <Link to={"/designs/add"} className="rounded-pill py-2 button-47">
                     Add Item
                  </Link> */}
                  <Link to={"/orders/add"} className="rounded-pill py-2  button-47">
                     Add Order
                  </Link>
                  {/* <Link to={"/accounts/add"} className="rounded-pill py-2  button-47">
                     Add Account
                  </Link> */}
                  {/* <Link to={"/transports/add"} className="rounded-pill py-2  button-47">
                     Add Transport
                  </Link> */}
               </div>
            </div>
            {/* <img height="250px" width="500px" src={mainFront} alt="main page" /> */}
         </div>
      </>
   )
}
export default Home
