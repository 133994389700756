import { Outlet } from "react-router-dom"
import MainNav from "./componant/MainNav"
function RootLayout() {
   return (
      <div className="RootLayout">
         <MainNav />
         <Outlet />
      </div>
   )
}

export default RootLayout
