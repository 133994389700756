import { Card, Col, Input, Button, Row, Form, Spinner, NavItem, NavLink, TabContent, TabPane, Nav } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { toast } from "react-hot-toast"
import Select from "react-select"
import { AddPlusIcon, InputOption, finYear, productObj, taxOptions } from "../../utils"
import { ArrowLeft } from "react-feather"
import { State } from "country-state-city"
import ItemWiseOrder from "./ItemWiseOrder"
import { getAllOrder, getOrderById, postOrder, putOrderById } from "../../myApiCenter"
import PdfGenerator from "../../componant/PDFGenerator"

const AddOrder = () => {
   const { id } = useParams()
   // console.log(id)
   const [stateOption, setstateOption] = useState([])
   const [active, setActive] = useState("1")
   const navigate = useNavigate()
   const [loading, setloading] = useState(false)
   const [data, setdata] = useState({
      orderNo: "",
      customer: {
         name: "",
         address: {
            addressLine: "",
            city: "",
            state: "Gujarat",
            pincode: ""
         },
         GSTNo: "",
         contactNo: ""
      },
      totalGST: { per: 18, total: 0 },
      CGST: { per: 9, total: 0 },
      SGST: { per: 9, total: 0 },
      orderDate: new Date().toISOString(),
      products: [
         {
            product_serial_no: 0,
            product_name: "",
            HSN: "",
            unit: "",
            quantity: 0,
            base_price: 0,
            total_price: 0
         }
      ],
      grandTotal: 0,
      totalWithoutGST: 0,
      description: ""
   })
   const [generatePdf, setGeneratePdf] = useState(false)
   const handleGeneratePdf = async () => {
      setGeneratePdf(true)

      setTimeout(() => {
         console.log("run")
         setGeneratePdf(false)
      }, 100)
   }

   const getData = async () => {
      const j = await getOrderById(id)
      console.log(j)
      delete j.data._id
      delete j.data.__v
      setdata({
         ...j.data,

         products: j.data.products.map((e) => {
            return {
               product_serial_no: e.product_serial_no,
               product_name: e.product_name,
               quantity: e.quantity,
               base_price: e.base_price,
               total_price: e.total_price
            }
         })
      })
   }
   const stateOptionHandler = () => {
      const indiaStates = State.getStatesOfCountry("IN") // 'IN' is the ISO code for India
      setstateOption(
         indiaStates.map((e) => {
            return { value: e.name, label: e.name }
         })
      )
      // const indiaCities = City.getCitiesOfCountry("IN") // 'IN' is the ISO code for India
      // setcityOption(
      //    indiaCities.map((e) => {
      //       return { value: e.name, label: e.name }
      //    })
      // )
   }
   const getOrderNo = async () => {
      const j = await getAllOrder()
      console.log(j)
      setdata({ ...data, orderNo: `${+j?.data?.length + 7}/${finYear}` })
      // setaccountOption(j)
   }

   // console.log(files)
   const submitHandler = async (e) => {
      e.preventDefault()
      const updatedProducts = data.products.map((e, index) => {
         return { ...e, product_serial_no: +index + 1 }
      })
      data.products = updatedProducts
      console.log(data)
      if (data) {
         try {
            setloading(true)
            let j
            if (id) {
               j = await putOrderById(id, data)
            } else {
               j = await postOrder(data)
            }
            j && navigate("/orders")
         } finally {
            setloading(false)
         }
      } else {
         toast.error("please select generic service")
      }
   }
   const toggle = (tab) => {
      if (active !== tab) {
         setActive(tab)
      }
   }
   useEffect(() => {
      id ? getData() : getOrderNo()

      stateOptionHandler()
      // getAccountOption()
      // getItemOption()

      return () => {}
   }, [id])
   // console.log(data)
   return (
      <Card style={{ height: "fit-content", width: "70vw", margin: "auto", padding: "10px" }}>
         <div className="d-flex align-items-center  ">
            <button style={{ border: "transparent" }} onClick={() => navigate(-1)}>
               {/* < style={{ color: "gray" }} /> */}
               <ArrowLeft />
            </button>
            <h2 className="ms-2 datatable-title-head">{id ? "Edit Order" : "Add Order"}</h2>
         </div>
         <div className="w-100">
            <Col>
               <Form className="d-flex justify-content-center gap-2 flex-column w-100" onSubmit={submitHandler}>
                  <Row>
                     <Col className="ms-3 mt-2 me-auto">
                        <p className="h4 me-auto ">Order No. : {data.orderNo}</p>
                     </Col>
                  </Row>
                  <Nav className="p-1" tabs>
                     <NavItem>
                        <NavLink
                           active={active === "1"}
                           onClick={() => {
                              toggle("1")
                           }}
                        >
                           {/* <SubjectListIcon size={18} /> */}
                           <span className="align-middle">Customer Detail</span>
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           active={active === "2"}
                           onClick={() => {
                              toggle("2")
                           }}
                        >
                           {/* <OpenBook size={18} /> */}
                           <span className="align-middle">Order Detail</span>
                        </NavLink>
                     </NavItem>
                  </Nav>
                  <TabContent className="" activeTab={active}>
                     <TabPane tabId="1">
                        <Row>
                           <Col className="ms-3 mt-2 me-auto">
                              <p className="h4 me-auto ">
                                 Customer Name <span style={{ color: "red" }}>*</span>
                              </p>
                              <Input
                                 value={data.customer?.name}
                                 required
                                 onChange={(e) => setdata({ ...data, customer: { ...data.customer, name: e.target.value } })}
                                 type="text"
                                 placeholder="Enter contact person name"
                              />
                           </Col>
                           <Col className="ms-3 mt-2">
                              <p className="h4">Mobile No</p>
                              <Input
                                 value={data.customer?.contactNo}
                                 // required
                                 onChange={(e) => setdata({ ...data, customer: { ...data.customer, contactNo: e.target.value } })}
                                 type="number"
                                 placeholder="ex. Your contact no"
                              />
                           </Col>
                        </Row>

                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">Address</p>
                              <textarea
                                 value={data.customer?.address?.addressLine}
                                 // required
                                 onChange={(e) => {
                                    const customerData = { ...data.customer }
                                    const address = { ...customerData.address }
                                    customerData.address = { ...address, addressLine: e.target.value }
                                    setdata({ ...data, customer: { ...customerData } })
                                 }}
                                 type="text"
                                 className="w-100 border-secondary rounded "
                                 placeholder="Enter Address"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">State</p>
                              <Select
                                 // isMulti
                                 // value={}

                                 options={stateOption}
                                 value={stateOption.filter((option) => option.value === data.customer?.address?.state)[0]}
                                 onChange={(e) => {
                                    const customerData = { ...data.customer }
                                    const address = { ...customerData.address }
                                    customerData.address = { ...address, state: e.value }
                                    setdata({ ...data, customer: { ...customerData } })
                                 }}
                                 components={{ Option: InputOption }}
                                 className="react-select my-filter-select"
                                 placeholder="Select from list"
                              />
                           </Col>
                           <Col className="ms-3 mt-2">
                              <p className="h4">City</p>

                              <Input
                                 value={data.customer?.address?.city}
                                 // required
                                 onChange={(e) => {
                                    const customerData = { ...data.customer }
                                    const address = { ...customerData.address }
                                    customerData.address = { ...address, city: e.target.value }
                                    setdata({ ...data, customer: { ...customerData } })
                                 }}
                                 type="text"
                                 placeholder="Enter city name"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">GST No</p>
                              <Input
                                 value={data.customer?.GSTNo}
                                 // required
                                 onChange={(e) => setdata({ ...data, customer: { ...data.customer, GSTNo: e.target.value } })}
                                 type="text"
                                 placeholder="Enter GST number"
                              />
                           </Col>
                           <Col className="ms-3 mt-2">
                              <p className="h4">Pin Code</p>
                              <Input
                                 value={data.customer?.address?.pincode}
                                 // required
                                 onChange={(e) => {
                                    const customerData = { ...data.customer }
                                    const address = { ...customerData.address }
                                    customerData.address = { ...address, pincode: e.target.value }
                                    setdata({ ...data, customer: { ...customerData } })
                                 }}
                                 type="number"
                                 placeholder="Enter pincode"
                              />
                           </Col>
                        </Row>
                        <Button type="button" className="w-25 mt-2 ms-3 my-btn" onClick={() => toggle("2")} disabled={loading}>
                           Next
                        </Button>
                     </TabPane>
                     <TabPane tabId="2">
                        <table>
                           <thead>
                              <tr>
                                 <th>No</th>
                                 <th>Item/Service Name</th>
                                 <th>HSN code </th>
                                 <th>Price(Rs.) </th>
                                 <th>Quantity</th>
                                 <th>Unit </th>
                                 <th>total(Rs.)</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              {data.products?.map((e, index) => {
                                 return <ItemWiseOrder key={index} data={e} parentData={data} setData={setdata} index={index} />
                              })}
                           </tbody>
                        </table>

                        <Row>
                           <Col className=" mt-2">
                              <div
                                 className="d-flex gap-1 w-100 cursor-pointer align-items-center rounded "
                                 style={{ border: "1px dashed gray", padding: "10px 5px", cursor: "pointer" }}
                                 onClick={() => {
                                    setdata((prev) => {
                                       return { ...prev, products: [...prev.products, { ...productObj }] }
                                    })
                                 }}
                              >
                                 <AddPlusIcon />
                                 <span>Add New Item</span>
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">Total Without GST(Rs.) :- {data.totalWithoutGST}</p>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="w-100 d-flex align-items-center justify-content-start ms-3 mt-2">
                              <p className="h5 me-3">CGST :</p>
                              <div style={{ minWidth: "150px" }}>
                                 <Select
                                    // isMulti
                                    // value={}
                                    options={taxOptions}
                                    value={taxOptions.filter((option) => option.value === data.CGST?.per)[0]}
                                    onChange={(e) => {
                                       setdata((prev) => {
                                          const oldCGST = { ...prev.CGST }
                                          oldCGST.total = Math.round((prev.totalWithoutGST * e.value) / 100)
                                          oldCGST.per = e.value
                                          return {
                                             ...prev,
                                             CGST: oldCGST,
                                             totalGST: { per: oldCGST.per + prev.SGST.per, total: oldCGST.total + prev.SGST.total },
                                             grandTotal: prev.totalWithoutGST + oldCGST.total + prev.SGST.total
                                          }
                                       })
                                    }}
                                    components={{ Option: InputOption }}
                                    className="react-select my-filter-select"
                                    placeholder="Select from list"
                                 />
                              </div>

                              <p className="h5 ms-2" style={{ minWidth: "180px" }}>
                                 total-CGST(Rs.) :{data.CGST.total}
                              </p>
                           </Col>
                           <Col className="w-100 d-flex align-items-center justify-content-start ms-3 mt-2">
                              <p className="h5 me-3">SGST :</p>
                              <div style={{ minWidth: "150px" }}>
                                 <Select
                                    // isMulti
                                    // value={}
                                    options={taxOptions}
                                    value={taxOptions.filter((option) => option.value === data.SGST?.per)[0]}
                                    onChange={(e) => {
                                       setdata((prev) => {
                                          const oldSGST = { ...prev.SGST }
                                          oldSGST.total = Math.round((prev.totalWithoutGST * e.value) / 100)
                                          oldSGST.per = e.value
                                          return {
                                             ...prev,
                                             SGST: oldSGST,
                                             totalGST: { per: oldSGST.per + prev.CGST.per, total: oldSGST.total + prev.CGST.total },
                                             grandTotal: prev.totalWithoutGST + oldSGST.total + prev.CGST.total
                                          }
                                       })
                                    }}
                                    components={{ Option: InputOption }}
                                    className="react-select my-filter-select"
                                    placeholder="Select from list"
                                 />
                              </div>

                              <p className="h5 ms-2" style={{ minWidth: "180px" }}>
                                 total-SGST(Rs.) :{data.SGST.total}
                              </p>
                           </Col>
                        </Row>
                        <Row></Row>

                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">
                                 Total GST{`(${data.totalGST.per} %)`}(Rs.) :- {data.totalGST.total}
                              </p>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">Grand Total(Rs.) :- {data.grandTotal}</p>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2">
                              <p className="h4">
                                 note <span style={{ color: "red" }}>*</span>
                              </p>

                              <textarea
                                 value={data.description}
                                 // required
                                 onChange={(e) => setdata({ ...data, description: e.target.value })}
                                 type="text"
                                 className="w-100 border-secondary rounded "
                                 placeholder="Enter Address"
                              />
                           </Col>
                        </Row>
                        <button className="rounded px-2" onClick={handleGeneratePdf}>
                           Preview
                        </button>
                        {generatePdf && <PdfGenerator data={data} />}

                        <Button className="w-25 mt-2 ms-3 my-btn" disabled={loading}>
                           Save {loading && <Spinner size="sm" />}
                        </Button>
                     </TabPane>
                  </TabContent>
               </Form>
            </Col>
         </div>
      </Card>
   )
}
export default AddOrder
