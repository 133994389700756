import React from "react"
import { numberToWords } from "../utils"

// import MyComponent from "./MyComponent"
export const PrintableComponent = React.forwardRef((props, ref) => {
   const { data } = props
   console.log(data)
   return (
      <div style={{ display: "none" }}>
         <div className="w-100 " style={{ border: "2px solid black" }} ref={ref}>
            <h3 className="py-1" style={{ textAlign: "center", borderBottom: "1px solid black" }}>
               TAX INVOICE
            </h3>
            <div className="d-flex" style={{ borderBottom: "2px solid black" }}>
               <div className="d-flex align-items-start flex-column p-1 w-100">
                  <h1 className="ms-2 datatable-title-head">SHREE RAM ENTERPRISE</h1>
                  <p>106, Shivanjali Row-House, Umra-velanja Road, Nr DGVCL office, Umra, Surat</p>
                  <p>State Name : Gujarat, Code : 24</p>

                  <p>
                     <strong> mobile No: </strong> 9558955058 <strong> GSTIN: </strong>24AWLPC3224F1Z1
                  </p>
               </div>
               <div className="w-100   d-flex align-items-start flex-column justify-content-between">
                  <div className="px-3 w-100" style={{ border: "2px solid gray", textAlign: "end" }}>
                     <p>
                        <strong> BILL NO :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderNo}
                     </p>
                     <p>
                        <strong> BILL DATE :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderDate?.split("T")[0].split("-").reverse().join("/")}
                     </p>
                  </div>
                  <div className="px-3 w-100" style={{ border: "2px solid gray", textAlign: "end", flex: "1" }}>
                     {/* <p>
             <strong> ORDER NO :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderNo}
          </p>
          <p>
             <strong> ORDER DATE :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderDate?.split("T")[0].split("-").reverse().join("/")}
          </p> */}
                  </div>
                  <div className="px-3 w-100" style={{ border: "2px solid gray", textAlign: "end", flex: "1" }}>
                     {/* <p>
             <strong> ORDER NO :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderNo}
          </p>
          <p>
             <strong> ORDER DATE :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderDate?.split("T")[0].split("-").reverse().join("/")}
          </p> */}
                  </div>
               </div>
            </div>

            <div className="px-2 d-flex flex-column" style={{ background: "#d3cdcd" }}>
               <h6>Customer Detail: </h6>
               <h6>{data && data.customer?.name}</h6>
               <p>
                  {data &&
                     `${data.customer?.address?.addressLine} ${data.customer?.address?.city}, ${data.customer?.address?.pincode}, ${data.customer?.address?.state}`}
               </p>
               <p>
                  <strong>Contact NO:</strong> {data && data.customer?.contactNo}
               </p>
               <p>
                  <strong>GST NO:</strong> {data && data.customer?.GSTNo}
               </p>
            </div>

            <table className="view-order-detail w-100">
               <thead>
                  <tr>
                     <th>No</th>
                     <th>Item/Service Name</th>
                     <th>HSN Code </th>
                     <th>Price </th>
                     <th>Quantity</th>
                     <th>Unit</th>
                     <th>total</th>
                  </tr>
               </thead>
               <tbody>
                  {data &&
                     data.products?.length > 0 &&
                     data.products.map((e, index) => {
                        return (
                           <tr key={index}>
                              <td>{e.product_serial_no}</td>
                              <td>{e.product_name}</td>
                              <td>{e.HSN} </td>

                              <td>{e.base_price} ₹ </td>
                              <td>{e.quantity}</td>
                              <td>{e.unit} </td>

                              <td>{e.total_price} ₹</td>
                           </tr>
                        )
                     })}
                  <tr>
                     <td style={{ textAlign: "end" }} colSpan="6">
                        total
                     </td>
                     <td>{data && data.totalWithoutGST} ₹</td>
                  </tr>
                  <tr>
                     <td style={{ textAlign: "end" }} colSpan="6">
                        CGST ({data && data.CGST?.per} %)
                     </td>
                     <td>{data && data.CGST?.total} ₹</td>
                  </tr>
                  <tr>
                     <td style={{ textAlign: "end" }} colSpan="6">
                        SGST ({data && data.SGST?.per} %)
                     </td>
                     <td>{data && data.SGST?.total} ₹</td>
                  </tr>
                  <tr>
                     <td style={{ textAlign: "end" }} colSpan="6">
                        GST ({data && data.totalGST?.per} %)
                     </td>
                     <td>{data && data.totalGST?.total} ₹</td>
                  </tr>
                  <tr>
                     <td style={{ textAlign: "end" }} colSpan="6">
                        Grand Total
                     </td>
                     <td>{data && data.grandTotal} ₹</td>
                  </tr>
                  <tr>
                     <td colSpan="2" style={{ textAlign: "end" }}>
                        Amount Chargeble (in Words)
                     </td>
                     <td colSpan="5">{data && numberToWords(data.grandTotal).toUpperCase()} Only</td>
                  </tr>
               </tbody>
            </table>
            <div className="p-2 d-flex  " style={{ background: "#d3cdcd" }}>
               <div className="w-50 ">
                  <p>
                     <strong>Declaration</strong>
                  </p>
                  <p className="pe-3">
                     We declare that this invoice shows the actual prise of the goods described and that all particulars are true and correct.1) goods
                     once sold will not be taken back or exchange.2) Payment should be made of this bill onlyby a/c payee cheque/DD/RTGS or NEFT of
                     SURAT branch. 3) Interest
                  </p>
               </div>
               <div>
                  <p>
                     <strong>Company Bank Detail</strong>
                  </p>
                  <div className="d-flex gap-3">
                     <span>Bank Name </span>
                     <span>:</span>
                     <span>Panjab National bank</span>
                  </div>
                  <div className="d-flex gap-3">
                     <span>A/C no </span>
                     <span>:</span>
                     <span>8958002100002966</span>
                  </div>
                  <div className="d-flex gap-3">
                     <span>Branch & ISFC Code </span>
                     <span>:</span>
                     <span>Utran, Surat & PUNB0895800</span>
                  </div>
               </div>
            </div>
            <div className=" d-flex  ">
               <div className="px-3 w-100" style={{ border: "2px solid gray", textAlign: "start", flex: "1" }}>
                  <p>Customers Seal and Signature</p>
                  {/* <p>
             <strong> ORDER DATE :&nbsp; &nbsp; &nbsp; </strong> {data && data.orderDate?.split("T")[0].split("-").reverse().join("/")}
          </p> */}
               </div>
               <div className="px-3 w-100" style={{ border: "2px solid gray", textAlign: "end", flex: "1" }}>
                  <p>
                     <strong>for Shree Ram Enterprise </strong>
                  </p>
                  <p>
                     <strong> Proprietor </strong>
                  </p>
                  <p>Authorised Signatory</p>
               </div>
            </div>
         </div>
      </div>
   )
})
